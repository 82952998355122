
export default {
  data: () => {
    return {};
  },
  mounted() {
    if (!process.server && !window.MktoForms2) {
      const script = document.createElement("script");
      setTimeout(() => {
        script.onload = this.loadMarketoForm;
      }, 2000);
      script.type = "text/javascript";
      script.src = "//app-sjp.marketo.com/js/forms2/js/forms2.min.js";
      document.head.appendChild(script);
    } else {
      this.loadMarketoForm();
    }
  },
  methods:{
   loadMarketoForm() {
      MktoForms2.loadForm(
        'https://app-sjp.marketo.com',
        '362-YWJ-377',
        this.$attrs.marketo_form_id,
        (form) => {
          MktoForms2.whenReady((form) => {
            // Add an onSuccess handler
            form.onSuccess((values, followUpUrl) => {
              // Get the form's jQuery element and hide it
              form.getFormElem().hide();
              document.getElementById('thankyou').style.display = 'block';
              // Return false to prevent the submission handler from taking the lead to the follow-up URL
              return false;
            });
          });
        }
      );
    },
  },
   head() {
    return {
        script: [
        {
          src: 'https://app-sjp.marketo.com',
          // Optionally, you can specify other attributes like async, defer, etc.
        },
        {
          src: 'https://app-sjp.marketo.com/js/forms2/js/forms2.min.js',
          // Optionally, you can specify other attributes like async, defer, etc.
        },
      ],
    };
  },
};
