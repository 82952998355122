
export default {
    data() {
        return {
            bannerUrl: "",
            selected_item: {},
            form_id: "3947",
            form_data: {
                form_type: "form1",
                form1_section: {},
                eventData: {
                    event: "",
                    category: "",
                    action: "",
                    label: ""
                }
            },
            activeIndex: 0,
            activeTabIndex: 0,
            visible: false,
            active: false,
            key: "",
            isActive: false,
            isDropdownOpen: false
        };
    },
    methods: {
        openPopup(item) {
            this.selected_item = item;
            if (item.has_gated_resource) {
                this.$refs["formPopup"].show();
                this.form_id = item.form_id;
                this.form_data.eventData = {
                    event: "TataFormSubmit",
                    category: "Form submit",
                    action: "Popup",
                    label: window.location.href
                };
            } else if (item.has_video) {
                this.bannerUrl = item.video_link;
                this.$refs["bannerModal"].show();
            }
        },
        formSubmitted() {
            this.$refs["formPopup"].hide();
            if (this.selected_item.has_video) {
                setTimeout(() => {
                    this.bannerUrl = this.selected_item.video_link;
                    this.$refs["bannerModal"].show();
                }, 500);
            } else {
                const a = document.createElement("a");
                a.setAttribute("href", this.selected_item.cta.url + "?r=1");
                a.setAttribute("target", "_blank");
                a.click();
            }
        },
        changeTab(index) {
            this.activeIndex = index;
        },
        selectTab(index) {
            this.activeIndex = index;
            this.toggleDropdown();
        },
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        }
    },
    mounted() {
        console.log("section162", this.$attrs);

    }
}
