
  import { Nudge } from 'nudge_core_browser_v2'
  import { onMounted, watch } from 'vue';
  
  const nudge = new Nudge({
    apiKey: process.env.NudgeCoreApiKey || "XXXXXX",
  });
  
  
  async function initFunc(nudgeData) {
    const { pageURL, trackerEventName } = nudgeData;

    try {
      if (!nudgeData || !pageURL|| !trackerEventName) {
        console.log("Invalid nudgeData provided");
        return;
      }
      await nudge.userIdentifier({}); // external id to hold a random unique number/id
      
      if(window.location.pathname === pageURL){
        await nudge.track({ event: trackerEventName });
      }

      if(window.location.pathname === '/'){
        await nudge.track({ event: "HOMEPAGE_STORY" });
      }

      if(window.location.pathname === '/solutions/network/cloud-ready-networks/izo-sdwan-clone/'){
        await nudge.track({ event: "IZO_AMP_STORY" });

        /* checking if form submitted */
        let checkSubmit = localStorage.getItem("checkSubmit");
        if(checkSubmit){
          return;
        }
        else{
          await nudge.track({ event: "IZO_MARKTO_FORM" });
        }
        /* remove nudge modal if form is submitted */
      }
    } catch (err) {
      console.log(err);
    } finally {
      console.log("nudge loaded");
    }
  }
  
  export default {
    name: "NuxtTutorial",
    props:["nudgeData"],
    /* Needs this code so not deleting for Now */
    // setup(props) {
    //   onMounted(() => {
    //     initFunc(props.nudgeData);  
    //   });
    // },
    mounted(){initFunc(this.nudgeData); },
    methods: {
      startDemo: async function () {
        await nudge.track({ event: "DEMO_NUDGES_EVENT" }); // type to hold a unique id specific to the nudge
      }
    },
  };
  