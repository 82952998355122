
import lottie from "vue-lottie/src/lottie.vue";
import upArrow from "@/assets/lottie/upArrow.json";
import { isDesktop } from "~/utils/device";

export default {
  components: {
    lottie
  },
  data() {
    return {
        anim: null, // for saving the reference to the animation
        lottieOptions: {
            animationData: upArrow,
            loop: true,
            autoplay: true
        }
    };
  },
  created(){
    if (process.client && isDesktop()) {
      window.addEventListener("scroll", this.scrollToTopCta);
    }
  },
  destroyed(){
    if (process.client && isDesktop()) {
      window.removeEventListener("scroll", this.scrollToTopCta);
    }
  },
  methods:{
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    scrollToTopCta(){
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollTopCta = document.querySelector('.scroll-btn');
        if (scrollTop > 750) {
            scrollTopCta.style.display = 'block';
        }else{
            scrollTopCta.style.display = 'none';
        }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scroll
      });
    }
  },
};
