
export default {
  data() {
    return {
      formData: []
    };
  },
  mounted() {
    this.formData = this.$attrs;
    this.formData.eventData = {
      event: "TataFormSubmit",
      category: "Form submit",
      action: "Page",
      label: window.location.href
    };
    if (process.client) {
      AOS.init({
        duration: 800,
        delay: 150,
        once: false,
        offset: 50,
      });
    }
  },
  methods: {
    isFormSubmitted() {
      if (this.$attrs.is_gmq_form) {
        document.getElementsByClassName("form-section")[0].classList.add("d-none");
        document.getElementsByClassName("gmq-download-section")[0].classList.remove("d-none");
        window.scrollTo({
          top: document.getElementsByClassName("gmq-download-section")[0].offsetTop - 150,
          behavior: "smooth"
        });
      }
    }
  }
};
