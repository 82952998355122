
export default {
  data() {
    return {
      ampStoryConfig: {
        behavior: {
          pageScroll: true,
          autoplay: true
        },
        controls: [
          {
            name: "close",
            position: "start"
          },
          {
            name: "skip-to-next"
          }
        ]
      }
    };
  },
  computed: {
    linkSets() {
      return [
        {
          route:
            "/solutions/network/cloud-ready-networks/izo-sdwan-managed-services/",
          links: [
            "https://stage.letschbang.com/amp-stories/sd-wan-amp-story-1/sd-wan-amp-1.html",
            "https://stage.letschbang.com/amp-stories/sd-wan-amp-story-2/sd-wan-amp-2.html",
            "https://stage.letschbang.com/amp-stories/sd-wan-amp-3-new/sd-wan-amp-3-new.html"
          ]
        },
        {
          route:
            "/solutions/unified-communications/unified-communications-as-a-service/microsoft-teams-solutions/",
          links: [
            "https://stage.letschbang.com/amp-stories/quick-glance-amp-1/quick-glance-amp-1.html",
            "https://stage.letschbang.com/amp-stories/connected-workforce/amp-story-two/amp-story-two.html",
            "https://stage.letschbang.com/amp-stories/msp-amp-story/msp-amp.html"
          ]
        },
        {
          route:
            "/solutions/unified-communications/unified-communications-as-a-service/microsoft-teams-solutions/direct-routing/",
          links: [
            "https://stage.letschbang.com/amp-stories/direct-amp-story/direct-amp-story.html",
            "https://stage.letschbang.com/amp-stories/connected-workforce/amp-story-one/amp-story-one.html",
            "https://stage.letschbang.com/amp-stories/msp-amp-story/msp-amp.html"
          ]
        },
        {
          route:
            "/solutions/unified-communications/unified-communications-as-a-service/globalrapide/",
          links: [
            "https://stage.letschbang.com/amp-stories/quick-glance-amp-2/quick-glance-amp-2.html",
            "https://stage.letschbang.com/amp-stories/case-study-amp/case-study-amp.html",
            "https://stage.letschbang.com/amp-stories/msp-amp-story/msp-amp.html"
          ]
        },
        
        {
          route: "/solutions/network/cloud-ready-networks/izo-internet-wan/",
          links: [
            "https://stage.letschbang.com/amp-stories/izo-internet-wan-new/izo-internet-wan.html",
            "https://stage.letschbang.com/amp-stories/amp-story-three/amp-story-three.html",
            "https://stage.letschbang.com/amp-stories/amp-story-two/amp-story-two.html"
          ]
        },
        {
          route: "/solutions/cloud-solutions/",
          links: [
            "https://stage.letschbang.com/amp-stories/new-solution-page-amp-2/new-solution-page-amp-2.html",
            "https://stage.letschbang.com/amp-stories/new-solution-page-amp-3/new-solution-page-amp-3.html",
            "https://stage.letschbang.com/amp-stories/new-solution-page-amp-4/new-solution-page-amp-4.html"
          ]
        },
        {
          route: "/solutions/cloud/",
          links: [
            "https://stage.letschbang.com/amp-stories/new-solution-page-amp-2/new-solution-page-amp-2.html",
            "https://stage.letschbang.com/amp-stories/new-solution-page-amp-3/new-solution-page-amp-3.html",
            "https://stage.letschbang.com/amp-stories/new-solution-page-amp-4/new-solution-page-amp-4.html"
          ]
        },
        
      ];
    }
  }
};
