import { render, staticRenderFns } from "./tata-ticker-video-cta-new.vue?vue&type=template&id=73f95d82&scoped=true"
import script from "./tata-ticker-video-cta-new.vue?vue&type=script&lang=js"
export * from "./tata-ticker-video-cta-new.vue?vue&type=script&lang=js"
import style0 from "./tata-ticker-video-cta-new.vue?vue&type=style&index=0&id=73f95d82&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73f95d82",
  null
  
)

export default component.exports