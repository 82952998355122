
export default {
  props: {
    eventCode: {
      type: String,
      default: ""
    },
  },
  data:() => {
    return{
      isSolutionType:true,
    }
  },
  mounted() {}
};
