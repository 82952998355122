
export default {
  props: {
    ctaClass: {
      type: [String, Array],
      default: ""
    },
    redirectLink: {
      type: String,
      default: "/"
    },
    target: {
      type: String,
      default: "Same Tab"
    },
    uniqueId:{
      type: String,
      default: ""
    }
  },
  methods: {
    urlLink(args) {
      if (args.indexOf("http://") === 0 || args.indexOf("https://") === 0) {
        return false;
      }else if(args === '#'){
        return false;
      }else if (args === "") {
        return false;
      } else {
        return true;
      }
    },
    openTarget(args) {
      if (args !== "Same Tab" || args === "_blank") {
        return "_blank";
      }
      // return args;
    },
    openPopup() {
      this.$nuxt.$emit("open-popup-form", "open");
    },
    gotoSection(section) {
      if (window.innerWidth < 500) {
        var options = {
          easing: "linear",
          lazy: false,
          offset: -100
        };
        this.$scrollTo(section, 500, options);
      } else {
        var options = {
          easing: "linear",
          lazy: false,
          offset: -140
        };
        this.$scrollTo(section, 500, options);
      }
    }
  }
};
