
export default {
  data() {
    return {
      // filteredData: [],
      paginationList: 9,
      allItems: [],
      filterItem: [],
      bannerUrl: "",
      filter: {
        partner: [],
        userType: [],
        search: ""
      },
      activeTab: 'North',
      northData: [],
      westData: [],
      persistData: [],
      currentModalIndex: null,
    };
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    filterData: function () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      if (this.activeTab === 'North') {
        this.persistData = [];
        this.persistData = this.northData;
        this.paginationList = 9;
        if (
          this.filter.partner.length &&
          this.filter.userType.length &&
          !this.filter.search
        ) {
          return this.persistData;
        } else {
          return this.persistData.filter((ele) => {
            let returnPartner = true;
            let returnUserType = true;
            let returnSearch = true;

            if (this.filter.partner.length) {
              let partnerList = ele.taxo.partners.map((ele) => {
                return ele.term_id;
              });
              returnPartner = !this.filter.partner.every((e) => {
                return !partnerList.includes(e);
              });
            }
            if (this.filter.userType.length) {
              let userTypeList = ele.taxo.users_type.map((ele) => {
                return ele.term_id;
              });
              returnUserType = !this.filter.userType.every((e) => {
                return !userTypeList.includes(e);
              });
            }
            if (this.filter.search) {
              returnSearch =
                ele.title.toLowerCase().indexOf(this.filter.search.toLowerCase()) > -1;
            }

            return (
              returnPartner &&
              returnUserType &&
              returnSearch
            );
          });
        }
      }
      if (this.activeTab === 'West') {
        this.persistData = [];
        this.persistData = this.westData;
        this.paginationList = 9;
        if (
          this.filter.partner.length &&
          this.filter.userType.length &&
          !this.filter.search
        ) {
          return this.persistData;
        } else {
          return this.persistData.filter((ele) => {
            let returnPartner = true;
            let returnUserType = true;
            let returnSearch = true;

            if (this.filter.partner.length) {
              let partnerList = ele.taxo.partners.map((ele) => {
                return ele.term_id;
              });
              returnPartner = !this.filter.partner.every((e) => {
                return !partnerList.includes(e);
              });
            }
            if (this.filter.userType.length) {
              let userTypeList = ele.taxo.users_type.map((ele) => {
                return ele.term_id;
              });
              returnUserType = !this.filter.userType.every((e) => {
                return !userTypeList.includes(e);
              });
            }
            if (this.filter.search) {
              returnSearch =
                ele.title
                  .toLowerCase()
                  .indexOf(this.filter.search.toLowerCase()) > -1;
            }

            return (
              returnPartner &&
              returnUserType &&
              returnSearch
            );
          });
        }
      }
    }
  },
  watch: {
    activeTab: function (value) {
      this.filter.partner = [];
      this.filter.userType = [];
      this.filter.search = "";
    }
  },

  mounted() {
    this.allItems = this.$attrs.items;
    this.filterItem = this.$attrs.taxonomies;

    let northData = this.allItems.filter(item => {
      return item.taxo.zone.name === 'North';
    });
    this.northData = northData;

    let westData = this.allItems.filter(item => {
      return item.taxo.zone.name === 'West';
    });
    this.westData = westData;
  },
  methods: {
    urlLink(args) {
      if (args.indexOf("http://") == 0 || args.indexOf("https://") == 0) {
        return true;
      } else if (args || args === undefined || args === null || args === "") {
        return false;
      } else {
        return true;
      }
    },
    openNCloseFilter() {
      let filter = this.$refs.filterList;
      if (filter.classList.contains("d-block")) {
        filter.classList.remove("d-block");
      } else {
        filter.classList.add("d-block");
      }
    },
    loadMore() {
      this.paginationList += 9;
    },
    showModal(data) {
      this.currentModalIndex = data;
      this.$refs["partnersDetailsModal"].show();
    },
    showBannerModal(val) {
      this.$refs["bannerModal"].show();
      const videoSrc = val;
      var videoSrcNew = videoSrc + "/?autoplay=1&rel=0&mute=1";
      this.bannerUrl = videoSrcNew;
    },
    sanitizeSearch() {
      this.filter.search = this.filter.search.replace(/[^a-zA-Z0-9 ]/g, "");
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
  }
};
