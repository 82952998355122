
// import { latData } from '~/assets/latency';
export default {
    data: () => {
        return {
            latData: [],
            firstCity: '',
            secondCity: '',
            sourceDC: '',
            destinationDC: '',
            selectedSourceDC: '',
            selectedDestinationDC: '',
            selectedFirstCity: '',
            selectedSecondCity: '',
            latencyValue: 0.0
        };
    },
    mounted(){
        this.latData =  JSON.parse(this.$attrs.content_editor);
    },
    props: {
        // firstCity: '',
        // secondCity: '',
        // sourceDC: '',
        // destinationDC: '',
        // selectedSourceDC: '',
        // selectedDestinationDC: '',
        // selectedFirstCity: '',
        // selectedSecondCity: '',
        // latencyValue: 0.0
    },
    methods: {
        onFirstCityChange() {
            this.firstCity = this.selectedFirstCity
            this.sourceDC = ''
            this.selectedSourceDC = ''
        },
        onSecondCityChange() {
            this.secondCity = this.selectedSecondCity
            this.destinationDC = ''
            this.selectedDestinationDC = ''
        },
        onSourceSelect() {
            this.sourceDC = this.selectedSourceDC
        },
        onDestinationSelect() {
            this.destinationDC = this.selectedDestinationDC
        }
    }
};
