
export default {
  data() {
    return {
      isVideoStuck: false,
      showGrayBackground: false,
      videoSrc: "https://www.youtube.com/embed/pfqkRYSs4Rg"
      // videoHeight: 0
    };
  },
  methods: {
    toggleVideoStuck() {
      this.isVideoStuck = !this.isVideoStuck;
    }
  }
};
