
import tataPrimaryCta from "../tata-primary-cta.vue";

export default {
  components: { tataPrimaryCta },
  data: () => {
    return {
      openVideo: false,
      activeIndex: -1,
      para: true,
      paramID:null,
      scrollOffset: 140
    };
  },
  head() {
    return {
      script: [
        {
          hid: "lottie-player",
          async: true,
          defer: true,
          src: "/lottie/lottie-player.js"
        },
        {
          innerHTML: `_linkedin_partner_id = "4101050";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);`,
          type: `text/javascript`,
          charset: "utf-8"
        },
        {
          innerHTML: `(function(l) {
          if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
          window.lintrk.q=[]}
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);})(window.lintrk);`,
          type: `text/javascript`,
          charset: "utf-8"
        },
        {
          innerHTML: `window.lintrk('track', { conversion_id: 9943274 });`,
          type: `text/javascript`,
          charset: "utf-8"
        }
      ],

      noscript: [
        {
          innerHTML:
            '<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=4101050&fmt=gif" />'
        }
      ]
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.onViewCardUrl();
    });
  },
  methods: {
    checkVideo(args) {
      this.activeIndex = args;
      this.openVideo = !this.openVideo;

      const videoDiv = document.getElementById(`video-${args}`);
      const videoSrc = videoDiv.src;
      var videoSrcNew = videoSrc + "/?autoplay=1&rel=0&mute=1";
      videoDiv.src = videoSrcNew;
    },
    close(index) {
      this.index = -1;
      this.openVideo = false;
      const el = document.getElementById(`video-${index}`);
      const elSrc = el.src;
      var closeVideo = elSrc.replace("autoplay=1", "autoplay=0");
      // el.src = elSrc;
    },
    onViewCardUrl() {
      let param = new URLSearchParams(window.location.search);
      this.paramID = param.get('id');
      if (this.paramID) {
        this.scrollOnViewCard(this.paramID);
      }
    },
    scrollOnViewCard(cardID) {
      const ele = document.getElementById(cardID);
      if (ele) {
        ele.scrollIntoView();
        const offset = this.scrollOffset; // Get the offset from data
        window.scrollBy(0, -offset); // Scroll up by the specified offset
      } else {
        console.error("Element not found: ", cardID);
      }
    }
  },
  
};
