export const state = () => ({
  awards: [],
  categories: []
});
export const getters = {
  allAwards: ({ awards }) => {
    return awards;
  },
  allCategories: ({ categories }) => {
    return categories;
  }
};
export const mutations = {
  setAwards(state, data) {
    state.awards = data;
  },
  setCategories(state, data) {
    state.categories = data;
  }
};
export const actions = {
  async getAllAwards({ commit }) {
    let categories = await this.$axios.get("/analyst-recognistion-awards/filters", {
      skip_meta: true
    });
    let response = await this.$axios.get("/analyst-recognistion-awards");
    if (response && response.data) {
      if (categories && categories.data) {
        commit("setCategories", categories.data);
      }
      return response.data;
    }
  }
};
