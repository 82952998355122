
  
  
  export default {
    components: {
    },
    data: () => {
      return {
      };
    },
    head:{
      script: [
        {src: "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js",},
        {src: "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.js",},
      ],
      link: [
        { rel:"stylesheet", type:"text/css", href:'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick-theme.min.css' },
        { rel:"stylesheet", type:"text/css", href:'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.css' }
      ]
    },
    mounted() {
      $( document ).ready(function() {
      $(".single-item").slick({
        infinite: true,
        arrows: false,
        dots: false,
        autoplay: false,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              arrows: true,
              autoplay: false,
              asNavFor: ".progressBarContainer",
            }
          }
        ]
      });
  
      if ($(window).width() < 600) {
          $(".progressBarContainer").slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          asNavFor: ".single-item",
          arrows: false,
        })
  
        $('.progressBarContainer li').click(function () {
          var goToThisIndex = $(this).find("span").data("slickIndex");
          $('.single-item').slick('slickGoTo', goToThisIndex, false);
        });
      }
  
      if ($(window).width() > 600) {
  
        //ticking machine
          var percentTime;
          var tick;
          var time = 1;
          var progressBarIndex = 0;
  
          $('.progressBarContainer .progressBar').each(function(index) {
              var progress = "<div class='inProgress inProgress" + index + "'></div>";
              $(this).html(progress);
          });
  
        function startProgressbar() {
            resetProgressbar();
            percentTime = 0;
  
            $('.progressBarContainer li').removeClass('active');
  
                var parent = $('.inProgress' + progressBarIndex).parent();
                parent.parent().addClass('active');
            tick = setInterval(interval, 10);
        }
  
        function interval() {
            if (($('.slider .slick-track div[data-slick-index="' + progressBarIndex + '"]').attr("aria-hidden")) === "true") {
                progressBarIndex = $('.slider .slick-track div[aria-hidden="false"]').data("slickIndex");
                startProgressbar();
            } else {
                percentTime += 1 / (time + 5);
                $('.inProgress' + progressBarIndex).css({
                    width: percentTime + "%"
                });
  
  
                if (percentTime >= 100) {
                    $('.single-item').slick('slickNext');
                    progressBarIndex++;
                    if (progressBarIndex > 2) {
                        progressBarIndex = 0;
                    }
                    startProgressbar();
                }
            }
        }
  
        function resetProgressbar() {
            $('.inProgress').css({
                width: 0 + '%'
            });
            clearInterval(tick);
        }
        startProgressbar();
        // End ticking machine
  
        $('.progressBarContainer li').click(function () {
          $(this).removeClass('active');
          $(this).addClass('active');
          clearInterval(tick);
          var goToThisIndex = $(this).find("span").data("slickIndex");
          $('.single-item').slick('slickGoTo', goToThisIndex, false);
          startProgressbar();
        });
      }
  
      });
  
    },
    methods: {
  
    }
  };
  