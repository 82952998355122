import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d2fb4580 = () => interopDefault(import('../pages/Customer-Story/index.vue' /* webpackChunkName: "pages/Customer-Story/index" */))
const _7ef8bf97 = () => interopDefault(import('../pages/development/index.vue' /* webpackChunkName: "pages/development/index" */))
const _0bf6e8b2 = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _156a613a = () => interopDefault(import('../pages/knowledge-base/index.vue' /* webpackChunkName: "pages/knowledge-base/index" */))
const _326842e2 = () => interopDefault(import('../pages/media-alert/index.vue' /* webpackChunkName: "pages/media-alert/index" */))
const _fb1e781e = () => interopDefault(import('../pages/news-centre/index.vue' /* webpackChunkName: "pages/news-centre/index" */))
const _38298604 = () => interopDefault(import('../pages/news-coverage/index.vue' /* webpackChunkName: "pages/news-coverage/index" */))
const _98168dd8 = () => interopDefault(import('../pages/preferences/index.vue' /* webpackChunkName: "pages/preferences/index" */))
const _fef20fce = () => interopDefault(import('../pages/press-release/index.vue' /* webpackChunkName: "pages/press-release/index" */))
const _55709f70 = () => interopDefault(import('../pages/resource/index.vue' /* webpackChunkName: "pages/resource/index" */))
const _e8fc95bc = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _40981703 = () => interopDefault(import('../pages/about/analyst-recognition.vue' /* webpackChunkName: "pages/about/analyst-recognition" */))
const _18f9b153 = () => interopDefault(import('../pages/about/awards-1.vue' /* webpackChunkName: "pages/about/awards-1" */))
const _570a837f = () => interopDefault(import('../pages/about/awards-clone.vue' /* webpackChunkName: "pages/about/awards-clone" */))
const _1ea506f8 = () => interopDefault(import('../pages/faq/search.vue' /* webpackChunkName: "pages/faq/search" */))
const _000e69c9 = () => interopDefault(import('../pages/knowledge-base/category/index.vue' /* webpackChunkName: "pages/knowledge-base/category/index" */))
const _43a09f70 = () => interopDefault(import('../pages/knowledge-base/search.vue' /* webpackChunkName: "pages/knowledge-base/search" */))
const _333cc505 = () => interopDefault(import('../pages/knowledge-base/tag/index.vue' /* webpackChunkName: "pages/knowledge-base/tag/index" */))
const _ea89b62a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _03506efe = () => interopDefault(import('../pages/knowledge-base/category/_slug.vue' /* webpackChunkName: "pages/knowledge-base/category/_slug" */))
const _318623bd = () => interopDefault(import('../pages/knowledge-base/tag/_slug.vue' /* webpackChunkName: "pages/knowledge-base/tag/_slug" */))
const _559c56d0 = () => interopDefault(import('../pages/resource/tag/_tag_slug.vue' /* webpackChunkName: "pages/resource/tag/_tag_slug" */))
const _d6688810 = () => interopDefault(import('../pages/Customer-Story/_slug.vue' /* webpackChunkName: "pages/Customer-Story/_slug" */))
const _2fd0166d = () => interopDefault(import('../pages/faq/_slug/index.vue' /* webpackChunkName: "pages/faq/_slug/index" */))
const _13b3bff2 = () => interopDefault(import('../pages/knowledge-base/_slug.vue' /* webpackChunkName: "pages/knowledge-base/_slug" */))
const _35d58572 = () => interopDefault(import('../pages/media-alert/_slug.vue' /* webpackChunkName: "pages/media-alert/_slug" */))
const _3b96c894 = () => interopDefault(import('../pages/news-coverage/_slug.vue' /* webpackChunkName: "pages/news-coverage/_slug" */))
const _7ed056d1 = () => interopDefault(import('../pages/press-release/_slug.vue' /* webpackChunkName: "pages/press-release/_slug" */))
const _0eaa5a68 = () => interopDefault(import('../pages/prize-winner/_slug.vue' /* webpackChunkName: "pages/prize-winner/_slug" */))
const _4c05dba7 = () => interopDefault(import('../pages/resource/_parent_cat_slug/index.vue' /* webpackChunkName: "pages/resource/_parent_cat_slug/index" */))
const _00739b7c = () => interopDefault(import('../pages/podcasts/_parent_cat_slug/_podcast_slug/index.vue' /* webpackChunkName: "pages/podcasts/_parent_cat_slug/_podcast_slug/index" */))
const _4653162e = () => interopDefault(import('../pages/resource/_parent_cat_slug/_child_cat_slug/index.vue' /* webpackChunkName: "pages/resource/_parent_cat_slug/_child_cat_slug/index" */))
const _5f813883 = () => interopDefault(import('../pages/resource/_parent_cat_slug/_child_cat_slug/_resources_slug/index.vue' /* webpackChunkName: "pages/resource/_parent_cat_slug/_child_cat_slug/_resources_slug/index" */))
const _bfbef690 = () => interopDefault(import('../pages/_l1/_slug.vue' /* webpackChunkName: "pages/_l1/_slug" */))
const _64b656f6 = () => interopDefault(import('../pages/_l1/_parent1/_slug.vue' /* webpackChunkName: "pages/_l1/_parent1/_slug" */))
const _723dae19 = () => interopDefault(import('../pages/_l1/_parent1/_parent2/_slug.vue' /* webpackChunkName: "pages/_l1/_parent1/_parent2/_slug" */))
const _ee39e474 = () => interopDefault(import('../pages/_l1/_parent1/_parent2/_parent3/_slug.vue' /* webpackChunkName: "pages/_l1/_parent1/_parent2/_parent3/_slug" */))
const _075eb37a = () => interopDefault(import('../pages/_l1/_parent1/_parent2/_parent3/_parent4/_slug.vue' /* webpackChunkName: "pages/_l1/_parent1/_parent2/_parent3/_parent4/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/Customer-Story",
    component: _d2fb4580,
    name: "Customer-Story"
  }, {
    path: "/development",
    component: _7ef8bf97,
    name: "development"
  }, {
    path: "/faq",
    component: _0bf6e8b2,
    name: "faq"
  }, {
    path: "/knowledge-base",
    component: _156a613a,
    name: "knowledge-base"
  }, {
    path: "/media-alert",
    component: _326842e2,
    name: "media-alert"
  }, {
    path: "/news-centre",
    component: _fb1e781e,
    name: "news-centre"
  }, {
    path: "/news-coverage",
    component: _38298604,
    name: "news-coverage"
  }, {
    path: "/preferences",
    component: _98168dd8,
    name: "preferences"
  }, {
    path: "/press-release",
    component: _fef20fce,
    name: "press-release"
  }, {
    path: "/resource",
    component: _55709f70,
    name: "resource"
  }, {
    path: "/search",
    component: _e8fc95bc,
    name: "search"
  }, {
    path: "/about/analyst-recognition",
    component: _40981703,
    name: "about-analyst-recognition"
  }, {
    path: "/about/awards-1",
    component: _18f9b153,
    name: "about-awards-1"
  }, {
    path: "/about/awards-clone",
    component: _570a837f,
    name: "about-awards-clone"
  }, {
    path: "/faq/search",
    component: _1ea506f8,
    name: "faq-search"
  }, {
    path: "/knowledge-base/category",
    component: _000e69c9,
    name: "knowledge-base-category"
  }, {
    path: "/knowledge-base/search",
    component: _43a09f70,
    name: "knowledge-base-search"
  }, {
    path: "/knowledge-base/tag",
    component: _333cc505,
    name: "knowledge-base-tag"
  }, {
    path: "/",
    component: _ea89b62a,
    name: "index"
  }, {
    path: "/knowledge-base/category/:slug",
    component: _03506efe,
    name: "knowledge-base-category-slug"
  }, {
    path: "/knowledge-base/tag/:slug",
    component: _318623bd,
    name: "knowledge-base-tag-slug"
  }, {
    path: "/resource/tag/:tag_slug?",
    component: _559c56d0,
    name: "resource-tag-tag_slug"
  }, {
    path: "/Customer-Story/:slug?",
    component: _d6688810,
    name: "Customer-Story-slug"
  }, {
    path: "/faq/:slug",
    component: _2fd0166d,
    name: "faq-slug"
  }, {
    path: "/knowledge-base/:slug?",
    component: _13b3bff2,
    name: "knowledge-base-slug"
  }, {
    path: "/media-alert/:slug?",
    component: _35d58572,
    name: "media-alert-slug"
  }, {
    path: "/news-coverage/:slug?",
    component: _3b96c894,
    name: "news-coverage-slug"
  }, {
    path: "/press-release/:slug?",
    component: _7ed056d1,
    name: "press-release-slug"
  }, {
    path: "/prize-winner/:slug?",
    component: _0eaa5a68,
    name: "prize-winner-slug"
  }, {
    path: "/resource/:parent_cat_slug",
    component: _4c05dba7,
    name: "resource-parent_cat_slug"
  }, {
    path: "/podcasts/:parent_cat_slug?/:podcast_slug",
    component: _00739b7c,
    name: "podcasts-parent_cat_slug-podcast_slug"
  }, {
    path: "/resource/:parent_cat_slug/:child_cat_slug",
    component: _4653162e,
    name: "resource-parent_cat_slug-child_cat_slug"
  }, {
    path: "/resource/:parent_cat_slug/:child_cat_slug/:resources_slug",
    component: _5f813883,
    name: "resource-parent_cat_slug-child_cat_slug-resources_slug"
  }, {
    path: "/:l1/:slug?",
    component: _bfbef690,
    name: "l1-slug"
  }, {
    path: "/:l1/:parent1?/:slug?",
    component: _64b656f6,
    name: "l1-parent1-slug"
  }, {
    path: "/:l1/:parent1?/:parent2?/:slug?",
    component: _723dae19,
    name: "l1-parent1-parent2-slug"
  }, {
    path: "/:l1/:parent1?/:parent2?/:parent3?/:slug?",
    component: _ee39e474,
    name: "l1-parent1-parent2-parent3-slug"
  }, {
    path: "/:l1/:parent1?/:parent2?/:parent3?/:parent4?/:slug?",
    component: _075eb37a,
    name: "l1-parent1-parent2-parent3-parent4-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
