export const solutionsCardCarousel = () => {
  return {
    perView: 3.3,
    focusAt: "center",
    type: "carousel",
    bullet: false,
    gap: 30,
    breakpoints: {
      992: {
        perView: 1.3,
        focusAt: "center"
      },
      768: {
        perView: 1.2,
        focusAt: "center",
        gap: 15,
      },
    }
  };
};
