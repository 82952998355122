
export default {
  props: ["sectionData", "isActive"],
  methods: {
    toggleCard() {
      this.$emit("activatedCard");
      this.$emit('video_Thumnail_Clicked', '#mainVideo')
    }
  }
};
