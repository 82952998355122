
export default {
  props: {
    videos: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedVideoIndex: 0
    };
  },
  methods: {
    selectVideo(index) {
      this.selectedVideoIndex = index;
      this.$emit("videoSelected", this.videos[index].src);
    }
  },
  mounted() {
    const thumbnails = this.$refs.thumbnails;
    thumbnails.scrollLeft = 0; // Start at the beginning

    // Optionally, add horizontal scrolling behavior to the thumbnail slider
    thumbnails.addEventListener("wheel", (event) => {
      event.preventDefault();
      thumbnails.scrollLeft += event.deltaY;
    });
  }
};
