
export default {
    data() {
        return {
            orangeCta: 'orange-cta',
        };
    },
    mounted() {

        gsap.registerPlugin(ScrollTrigger);

        ScrollTrigger.defaults({
            markers: false
        });
        var points = gsap.utils.toArray('.dbPoint');
        const tabs = gsap.utils.toArray(".left-content .img");
        var indicators = gsap.utils.toArray('.indicator');
        var active;

        const colors = [
            { index1: "#05a7d2", index2: "#2fc9f2" },
            { index1: "#0A6AC2", index2: "#1279D7" },
            { index1: "#2483D4", index2: "#0cbeed" },
            { index1: "#023792", index2: "#144DAE" },
        ];

        var height = 100 * points.length;

        gsap.set('.indicators', { display: "flex" });

        var tl = gsap.timeline({
            duration: points.length,
            scrollTrigger: {
                trigger: ".main_container",
                start: "top center",
                end: "+=" + height + "%",
                scrub: true,
                id: "points",

                onUpdate: (self) => {
                    const progress = self.progress;
                    const index = Math.round(progress * (points.length - 1));
                    if (index !== active) {
                        updateTabState(index);
                    }
                }
            }
        });

        var pinner = gsap.timeline({
            scrollTrigger: {
                trigger: ".main_container .db_Wrapper",
                start: "top top",
                end: "+=" + height + "%",
                scrub: true,
                pin: ".main_container .db_Wrapper",
                pinSpacing: true,
                id: "pinning",
                markers: false,
            }
        });

        points.forEach(function (elem, i) {
            gsap.set(elem, { position: "absolute", top: 0 });

            // Change tab colors and manage active states
            tl.to(indicators[i], { backgroundColor: "#fff", border: "2px solid #003083", duration: 0.25 }, i);
            tl.from(elem.querySelector('img'), { autoAlpha: 0 }, i);
            tl.from(elem.querySelector('article'), { autoAlpha: 0, translateY: 100 }, i);

            if (i != points.length - 1) {
                tl.to(indicators[i], { backgroundColor: "#003083", duration: 0.25 }, i + 0.75);
                tl.to(elem.querySelector('article'), { autoAlpha: 0, translateY: -100 }, i + 0.75);
                tl.to(elem.querySelector('img'), { autoAlpha: 0 }, i + 0.75);
            }
        });

        // Function to update tab state and color
        function updateTabState(index) {
            if (active !== undefined) {
                tabs[active].classList.remove("selected");
                resetTabColors(tabs[active]);
            }

            tabs[index].classList.add("selected");
            setTabColors(tabs[index], colors[index]);
            active = index;
        }

        function resetTabColors(tab) {
            const prevPaths = tab.querySelectorAll("path");
            prevPaths.forEach((path, pathIndex) => {
                if (pathIndex === 0) path.style.fill = "#DDDDDD";
                if (pathIndex === 1) path.style.fill = "url(#paint0_linear_1410_49073)";
            });

            const prevLinearStops = tab.querySelectorAll("linearGradient stop");
            prevLinearStops.forEach((stop, stopIndex) => {
                if (stopIndex === 0) stop.style.stopColor = "#F8F8F8";
                if (stopIndex === 1) {
                    stop.style.stopColor = "#ECECEC";
                    stop.style.opacity = "0.5"
                };
            });
        }

        function setTabColors(tab, color) {
            const currentPaths = tab.querySelectorAll("path");
            currentPaths.forEach((path, pathIndex) => {
                if (pathIndex === 0) path.style.fill = color.index1;
                if (pathIndex === 1) path.style.fill = color.index2;
            });
        }

        // Add click-to-scroll functionality for tabs
        tabs.forEach((tab, i) => {
            tab.addEventListener("click", (e) => {
                e.preventDefault();
                const st = tl.scrollTrigger;

                gsap.to(window, {
                    scrollTo: {
                        y: st.start + (st.end - st.start) * (i / (points.length - 1)),
                    },
                    duration: 0.4,
                    ease: "power1.inOut",
                });
            });
        });

        // Set the initial state for the first tab
        window.addEventListener("load", () => {
            updateTabState(0); // Update the first tab's color on page load
            ScrollTrigger.refresh();
        });
    }
}
