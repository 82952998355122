export const state = () => ({
  resources: [],
  allResources: [],
  showSearch: false,
  filteredResources: [],
  featuredResource: [],
  breadcrumbs: [],
  resourceUnlocked: false,
  getPageData: {},
});

export const getters = {
  dataToShow: ({ resources, showSearch, filteredResources }) => {
    let returnVal = resources;
    if (showSearch) {
      returnVal = filteredResources;
    }
    return returnVal;
  },
  featuredResource: ({ featuredResource }) => {
    return featuredResource;
  },
  allData: ({ allResources }) => {
    let returnVal = [];
    allResources.forEach((e) => {
      if (e.taxo.type && e.taxo.year) returnVal.push(e);
    });
    return returnVal;
  },
  showSearch: ({ showSearch }) => {
    return showSearch;
  },
  breadcrumbs: ({ breadcrumbs }) => {
    return breadcrumbs;
  },
  resourceUnlocked: ({ resourceUnlocked }) => {
    return resourceUnlocked;
  },
  getPageData: ({ getPageData }) => {
    return getPageData;
  }
};

export const mutations = {
  updateResources(state, data) {
    state.resources = data;
  },
  updateFilter(state, data) {
    state.filteredResources = data;
  },
  updateShowSearch(state, data) {
    state.showSearch = data;
  },
  updateFeaturedResource(state, data) {
    state.featuredResource = data;
  },
  updateAllResources(state, data) {
    state.allResources = data;
  },
  updateBreadcrumbs(state, data) {
    state.breadcrumbs = data;
  },
  updateResourceUnlock(state, data) {
    state.resourceUnlocked = data;
  },
  updatePageData(state, data) {
    state.getPageData = data;
  }
};

export const actions = {
  async getResourcePage({}) {
    try {
      return await this.$axios.get("resource/taxonomy", {
        useCache: true
      });
    } catch (err) {
      console.error(err);
    }
  },
  async getResourceRightData({ commit, dispatch }, { params, cat_slug }) {
    try {
      const res = await this.$axios.get("resource", {
        params,
        useCache: true
      });
      if (res.data) {
        dispatch("getAllResourcesData", cat_slug);
        commit("updatePageData", res.data);
        commit("updateResources", res.data.items);
        commit("updateBreadcrumbs", res.data.breadscrum);
        commit("l1/updatePageTitle", res.data.breadscrum.ancestors[0].name, {
          root: true
        });
        commit("updateFeaturedResource", res.data.featured_resources);
        commit("l1/updateItemSpecificData", null, { root: true });
      }
    } catch (e) {
      console.error(e);
    }
  },
  async getResourceTagData({ commit }, slug) {
    try {
      const res = await this.$axios.get("resource/tag", {
        params: slug,
        useCache: true
      });
      // dispatch("getAllResourcesData", cat_slug);
      commit("updateResources", res.data.items);
      commit("updateBreadcrumbs", res.data.breadscrum);
      commit("l1/updatePageTitle", res.data.breadscrum.ancestors[0].name, {
        root: true
      });
      commit("updateFeaturedResource", res.data.featured_resources);
    } catch (e) {
      console.error(e);
    }
  },
  async getResourceInnerData({}, params) {
    try {
      const res = await this.$axios.get("resource", {
        params,
        useCache: true
      });

      return res ? res.data : {};
    } catch (e) {
      console.error(e);
    }
  },
  async getAllResourcesData({ commit }, params) {
    try {
      const res = await this.$axios.get("resource/all", {
        params,
        useCache: true
      });
      commit("updateAllResources", res.data.items);
    } catch (err) {
      console.error(err);
    }
  }
};
