
  export default {
    data: () => {
      return {};
    },
    mounted() {
      let siteEngager = document.createElement('script');
      siteEngager.setAttribute('src', 'https://content.cdntwrk.com/components/website-widget/v1/129530/widget.js');
      document.head.appendChild(siteEngager);
    }
  };
  