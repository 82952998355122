
import { mapState } from "vuex";
import vueCustomScrollbar from "vue-custom-scrollbar";
import TataLink from "~/components/global-components/tata-link";
import TataMobileNavigation from "~/components/global-components/tata-mobile-navigation-new";

export default {
  components: {
    vueCustomScrollbar,
    TataMobileNavigation,
    TataLink,
  },
  beforeRouteEnter() {
    let { store } = this.$nuxt.context;
    this.showCustomDropdown = false;
  },
  emit: ["toggleOverlayView"],
  data: () => {
    return {
      mainMenuToggler: false,
      showMenu: false,
      showMegaMenu: false,
      showCustomDropdown: false,
      customDropdownVal: false,
      subMegaContent: false,
      submenuContent: false,
      activeIndex: -1,
      activeMenuIndex: 0,
      defaulltRightMenuIndex: 0,
      showSecondaryMenu: null,
      activeTitleLink: null,
      activeTitleIndex: -1,
      activeMegaIndex: 0,
      activeSubmenu: false,
      menuActiveIndex: -1,
      levelTwoMenu: false,
      navChildMenu: false,
      navChildSubMenu: false,
      activeChildSubMenuIndex: -1,
      searchFlag: false,
      mobSearchBox: false,
      showChildPageDropdown: false,
      levelTwoIndex: -1,
      levelTwoItem: {},
      levelThreeMenu: false,
      levelThreeItem: {},
      levelThreeIndex: -1,
      search: "",
      searchData: false,
      isOpen: false,
      settings: {
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: false
      },
      notification_banner_closed: false
    };
  },
  computed: {
    ...mapState(["menuData"]),
    ...mapState("l1", ["pageTitle"]),
    ...mapState("l1", ["itemSpecificData"]),
    headerMenu: function () {
      if (this.menuData) {
        return this.menuData.header_menus;
      } else {
        return [];
      }
    }
  },
  async fetch() {
    try {
      await this.$store.dispatch("updateMenu");
    } catch (error) {
      console.log(error);
    }
  },
  watch: {
    $route() {
      this.search = "";
      this.searchData = false;
      this.mainMenuToggler = false;
      this.navChildMenu = false;
      this.isOpen = false;
      this.showCustomDropdown = false;
      this.levelThreeMenu = false;
      this.levelTwoMenu = false;
      this.showMenu = false;
      this.activeSubmenu = false;
      this.menuActiveIndex = -1;
      this.levelThreeIndex = -1;
      this.levelThreeItem = {};
      this.levelTwoIndex = -1;
      this.levelTwoItem = {};
      this.notification_banner_closed = false;
      this.mobSearchBox = false;
      this.search = this.$route.query.q;
      this.$disposSearch();
      setTimeout(() => {
        if (
          document.querySelector("#mobile_search") !== null &&
          document.querySelector("#nav__search-input") !== null
        ) {
          this.$initalizedSearch();
        }
      }, 500);
    }
  },
  mounted() {
    this.$disposSearch();
    if (
      document.querySelector("#mobile_search") &&
      document.querySelector("#nav__search-input")
    ) {
      this.$initalizedSearch();
    }
    document.addEventListener("click", this.handleClickOutside);
  },
  methods: {
    clickInside() {
      this.showMegaMenu = false;
    },
    toggleMegaMenuOverlay(param) {
      this.$emit("toggleOverlayView", param);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
      this.levelThreeMenu = false;
      this.levelTwoMenu = false;
      this.activeSubmenu = false;
    },
    contact_us_cta() {
      if (
        this.itemSpecificData &&
        this.itemSpecificData.contact_us_cta &&
        this.itemSpecificData.contact_us_cta.text
      ) {
        return this.itemSpecificData.contact_us_cta;
      } else {
        return { text: "Contact Us", url: "/contact-us", open_in: "Same Tab" };
      }
    },
    submitSearch() {
      this.$router.push(`/knowledge-base/search?q=${this.search}`);
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false;
      }
    },
    isCountryPage() {
      return this.itemSpecificData &&
        this.itemSpecificData
          .should_the_api_for_this_page_or_item_have_header_and_footer_menu
        ? this.itemSpecificData
          .should_the_api_for_this_page_or_item_have_header_and_footer_menu
        : false;
    }
  }
};
