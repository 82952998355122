
import { Glide, GlideSlide } from "vue-glide-js";
import { section45 } from "assets/sliderConfig/section45";
export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  data: () => {
    return {
      section45: section45()
    };
  },
  computed: {},
  mounted() {},
};
