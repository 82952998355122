
import { Glide, GlideSlide } from "vue-glide-js";
import PodcastDetails from "~/components/global-components/podcast-details.vue";
export default {
    components: {
        PodcastDetails,
        [Glide.name]: Glide,
        [GlideSlide.name]: GlideSlide,
    },
    data: () => {
        return {
            filter: {
                category: [],
            },
            slider_model: [],
            years: [],
            selectedYearArr: [],
            activeTab: 0,
            allPodcasts: [],
            filteredPodcast: [],
            filteredData: [],
            filteredYear: [],
            categories: [],
            pagination: 4,
            yearSwiper: null,
            searchQuery: "",
        };
    },
    async fetch() {
        let { store } = this.$nuxt.context;
        let podcasts = await store.dispatch("podcast/getAllPodcasts");

        if (!podcasts) return;
        this.allPodcasts = podcasts = podcasts?.all_podcasts;
        this.categories = store.getters["podcast/allCategories"];

        this.years = this.filteredYear = Object.keys(this.allPodcasts).sort(function (a, b) {
            return b - a;
        });
    },
    computed: {
        filteredPodcastBySearch() {
            if (!this.searchQuery.trim()) {
                return this.filteredPodcast;
            }

            const query = this.searchQuery.trim().toLowerCase();
            let filtered = {};

            Object.keys(this.filteredPodcast).forEach((year) => {
                const podcasts = this.filteredPodcast[year].filter((podcast) =>
                    podcast.title?.toLowerCase().includes(query)
                );

                if (podcasts.length > 0) {
                    filtered[year] = podcasts;
                }
            });

            return filtered;
        },
    },
    watch: {
        filter: {
            deep: true,
            handler({ category }) {
                let filterd_array = [];
                if (category.length) {
                    filterd_array = [];
                    this.pagination = 4;
                    if (!this.allPodcasts) return;
                    Object.keys(this.allPodcasts).filter((year) => {
                        this.allPodcasts[year].filter((ele) => {
                            if (ele.category) {
                                ele.category.forEach((item) => {
                                    if (category.includes(item.term_id)) {
                                        if (!filterd_array[year]) {
                                            filterd_array[year] = [];
                                        }
                                        filterd_array[year].push(ele);
                                    }
                                });
                            }
                        });
                    });
                    this.filteredYear = [];
                    if (!filterd_array) return;
                    this.filteredYear = Object.keys(filterd_array).sort((a, b) => {
                        return b - a;
                    });
                    this.filteredPodcast = filterd_array;
                } else {
                    this.filteredPodcast = this.allPodcasts;
                    this.filteredYear = this.years;
                }
            }
        }
    },
    methods: {
        initializeSwiper() {
            if (typeof Swiper !== 'undefined') {
                this.yearSwiper = new Swiper(".mySwiper", {
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                });
            } else {
                console.error('Swiper is not defined');
            }
        },
        openNCloseFilter() {
            let filter = this.$refs.filterList;
            if (filter.classList.contains("d-block")) {
                filter.classList.remove("d-block");
            } else {
                filter.classList.add("d-block");
            }
        },
        *chunks(arr, n) {
            for (let i = 0; i < arr.length; i += n) {
                yield arr.slice(i, i + n);
            }
        },

        handleYearclick(selectedYear) {
            let secondaryToLatestYear = this.years[1];
            let latestyear = this.years[0];

            let currentSelectedYear = this.selectedYearArr;  //[2021]

            //** checks if the selected year is already available in seletedYearArr
            // do nothing if true
            if (currentSelectedYear.includes(Number(selectedYear))) {
                return null;
            }
            //**

            ///** save selected year in an array. keeping everything unique and sorted
            currentSelectedYear.push(Number(selectedYear));
            let uniqueCurrentSelectedYear = [...new Set(currentSelectedYear)];
            uniqueCurrentSelectedYear.sort((a, b) => b - a);
            ///**

            // if the seletcted year is latest or secondary to latest year then do nothing e.g. for 2024 and 2023 do nothing
            if (secondaryToLatestYear <= selectedYear) {
                return null;
            }

            ///** extract minimum and maximum year from the selectedYearArr
            let selectedYearLength = uniqueCurrentSelectedYear.length;
            let maxSelectedYear = uniqueCurrentSelectedYear[0];
            let minselectedYear = uniqueCurrentSelectedYear[selectedYearLength - 1];
            let numOfTimeToCallLoadMore = (minselectedYear + 1) - selectedYear;
            ///**

            // if selectedYearArr length is 1.
            if (maxSelectedYear == minselectedYear) {
                numOfTimeToCallLoadMore = secondaryToLatestYear - selectedYear
            }

            let newyeararr = [];
            for (let i = Number(selectedYear); i < latestyear; i++) {
                newyeararr.push(i);  // [2023, 2023, 2022, 2021]
            }
            this.selectedYearArr = [...new Set(newyeararr)];

            for (let i = 0; i < numOfTimeToCallLoadMore; i++) {
                this.loadMore();
            }
        },

        loadMore() {
            this.pagination += 1;
        },

        addMoreYear() {
            let allSelectedyear = [];
            let latestyear = this.years[0];
            let minimumYear = latestyear - (this.pagination - 1);

            for (let i = minimumYear; i <= latestyear; i++) {
                allSelectedyear.push(i);
            }

            this.selectedYearArr = allSelectedyear;
        },
        sanitizeSearch() {
            this.searchQuery = this.searchQuery.replace(/[^a-zA-Z0-9 ]/g, "");
        },
    },
    mounted() {
        this.initializeSwiper();
    }
};
