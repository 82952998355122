
export default {
    methods: {
    // handleParent2Hover() {
    //   const leftTopCard = document.querySelector(".leftFirstCard");
    //   leftTopCard.style.backgroundColor = "#eceef1";
    // }
    },
    data: () => {
        return {};
    },
    mounted() {
        let leftCardOne = document.querySelector(".leftFirstCard");
        let leftCardTwo = document.querySelector(".leftBottomCard");
        let rightCardOne = document.querySelector(".stats_cardswrap");
        let rightCardTwo = document.querySelector(".expertWrapper");
        leftCardTwo.addEventListener("mouseenter", () => leftCardOne.classList.add("active"));
        leftCardTwo.addEventListener("mouseleave", () => leftCardOne.classList.remove("active"));
        rightCardOne.addEventListener("mouseenter", () => leftCardOne.classList.add("active"));
        rightCardOne.addEventListener("mouseleave", () => leftCardOne.classList.remove("active"));
        rightCardTwo.addEventListener("mouseenter", () => leftCardOne.classList.add("active"));
        rightCardTwo.addEventListener("mouseleave", () => leftCardOne.classList.remove("active"));
    },
};
