import { render, staticRenderFns } from "./tata-product-card.vue?vue&type=template&id=93bad8b6&scoped=true"
import script from "./tata-product-card.vue?vue&type=script&lang=js"
export * from "./tata-product-card.vue?vue&type=script&lang=js"
import style0 from "./tata-product-card.vue?vue&type=style&index=0&id=93bad8b6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93bad8b6",
  null
  
)

export default component.exports