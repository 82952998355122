
export default {
  props: {
    src: {
      type: String,
      required: true
    },
    width: {
      type: String
      //   default: "100%"
    },
    height: {
      type: String
      //   default: "100%"
    },
    stopVideo: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2 // Percentage of element visibility required to trigger loading
    };

    this.intersectionObserver = new IntersectionObserver(
      this.onIntersection,
      options
    );

    this.intersectionObserver.observe(this.$refs.videoContainer);
  },
  beforeDestroy() {
    if (this.intersectionObserver) {
      this.intersectionObserver.unobserve(this.$refs.videoContainer);
    }
  },
  methods: {
    onIntersection(entries) {
      if (entries[0].isIntersecting) {
        this.loadVideo();
        this.intersectionObserver.disconnect();
      }
    },
    loadVideo() {
      const iframe = document.createElement("iframe");
      iframe.width = this.width;
      iframe.height = this.height;
      iframe.frameborder = "0";
      iframe.allowfullscreen = "allowfullscreen";
      iframe.allow = "autoplay;";
      iframe.src = this.src;
      iframe.id = "customIframeID";

      this.$refs.videoContainer.appendChild(iframe);
    },
    stopIframe() {
      const iframeId = document.querySelector("#customIframeID");
      let temp = iframeId.src;
      iframeId.src = temp;
    }
  },
  watch: {
    stopVideo: function (newValue, oldValue) {
      if (!newValue) {
        this.stopIframe();
      }
    }
  }
};
