
export default {
  data() {
    return {
      ampStoryConfig: {
        behavior: {
          pageScroll: true,
          autoplay: true
        },
        controls: [
          {
            name: "close",
            position: "start"
          },
          {
            name: "skip-to-next"
          }
        ]
      }
    };
  },
  computed: {
    ampStoryLinks() {
      return [
        {
          route: "/powering-hyperconnected-ecosystems-1/",
          links: [
            "https://stage.letschbang.com/amp-stories/amp-story-four-new/amp-story-four-new.html",
            "https://stage.letschbang.com/amp-stories/hce-amp-story-new/hce-amp-story-new.html",
            // 'https://stage.letschbang.com/amp-stories/amp-story-six/amp-story-six.html'
            "https://stage.letschbang.com/amp-stories/hce-amp-story-3/hce-amp-story-3.html"
          ]
        },
        {
          route: "/powering-hyperconnected-ecosystems/",
          links: [
            "https://www.tatacommunications.com/amp-stories/amp-story-four-new/amp-story-four-new.html",
            "https://www.tatacommunications.com/amp-stories/hce-amp-story-new/hce-amp-story-new.html",
            "https://www.tatacommunications.com/amp-stories/amp-story-six/amp-story-six.html"
          ]
        },
        {
          route: "/connected-workforce-1/",
          links: [
            "https://stage.letschbang.com/amp-stories/connected-workforce/amp-story-one/amp-story-one.html",
            "https://stage.letschbang.com/amp-stories/connected-workforce/amp-story-two/amp-story-two.html",
            "https://stage.letschbang.com/amp-stories/connected-workforce/amp-story-three/amp-story-three.html"
          ]
        },
        {
          route: "/powering-hyperconnected-ecosystems/connected-workforce/",
          links: [
            "https://www.tatacommunications.com/amp-stories/connected-workforce/amp-story-one/amp-story-one.html",
            "https://www.tatacommunications.com/amp-stories/connected-workforce/amp-story-two/amp-story-two.html",
            "https://www.tatacommunications.com/amp-stories/connected-workforce/amp-story-three/amp-story-three.html"
          ]
        },
        {
          route: "/connected-customers-1/",
          links: [
            "https://stage.letschbang.com/amp-stories/connected-customers/amp-story-one/amp-story-one.html",
            "https://stage.letschbang.com/amp-stories/connected-customers/amp-story-two/amp-story-two.html",
            "https://stage.letschbang.com/amp-stories/connected-customers/amp-story-three/amp-story-three.html"
          ]
        },
        {
          route: "/powering-hyperconnected-ecosystems/connected-customers/",
          links: [
            "https://www.tatacommunications.com/amp-stories/connected-customers/amp-story-one/amp-story-one.html",
            "https://www.tatacommunications.com/amp-stories/connected-customers/amp-story-two/amp-story-two.html",
            "https://www.tatacommunications.com/amp-stories/connected-customers/amp-story-three/amp-story-three.html"
          ]
        },
        {
          route: "/connected-solutions-1/",
          links: [
            "https://stage.letschbang.com/amp-stories/connected-solutions/amp-story-one/amp-story-one.html",
            "https://stage.letschbang.com/amp-stories/connected-solutions/amp-story-two/amp-story-two.html",
            "https://stage.letschbang.com/amp-stories/connected-solutions/amp-story-three/amp-story-three.html"
          ]
        },
        {
          route: "/powering-hyperconnected-ecosystems/connected-solutions/",
          links: [
            "https://www.tatacommunications.com/amp-stories/connected-solutions/amp-story-one/amp-story-one.html",
            "https://www.tatacommunications.com/amp-stories/connected-solutions/amp-story-two/amp-story-two.html",
            "https://www.tatacommunications.com/amp-stories/connected-solutions/amp-story-three/amp-story-three.html"
          ]
        },
        {
          route: "/connected-infrastructure-1/",
          links: [
            "https://stage.letschbang.com/amp-stories/connected-infrastructure/amp-story-one/amp-story-one.html",
            "https://stage.letschbang.com/amp-stories/connected-infrastructure/amp-story-two/amp-story-two.html",
            "https://stage.letschbang.com/amp-stories/connected-infrastructure/amp-story-three/amp-story-three.html"
          ]
        },
        {
          route:
            "/powering-hyperconnected-ecosystems/connected-infrastructure/",
          links: [
            "https://www.tatacommunications.com/amp-stories/connected-infrastructure/amp-story-one/amp-story-one.html",
            "https://www.tatacommunications.com/amp-stories/connected-infrastructure/amp-story-two/amp-story-two.html",
            "https://www.tatacommunications.com/amp-stories/connected-infrastructure/amp-story-three/amp-story-three.html"
          ]
        }
      ];
    }
  },
  props: {
    eventCode: {
      type: String,
      default: ""
    }
  },
  data: () => {
    return {};
  },
  mounted() {},
  methods: {}
};
