
import { caseStudyCarousel } from "~/assets/sliderConfig/section42";

export default {
  data() {
    // let allowTouchMove = true;
    // if (typeof window !== "undefined") {
    //   allowTouchMove = !window.matchMedia("(min-width: 768px)").matches;
    // }
    return {
      // swiperOptions: {
      //   slidesPerView: 1.07,
      //   loop: true,
      //   speed: 700,
      //   spaceBetween: 20,
      //   initialSlide: 1,
      //   allowTouchMove: allowTouchMove,
      //   on: {
      //     init: function () {
      //       let slideheight =
      //         document.querySelector(".swiper-container").clientHeight;
      //       document.querySelector(".controller_holder").style.height =
      //         slideheight + "px";
      //       let holders = document.querySelectorAll(".controller_hold");
      //       holders.forEach((holder) => {
      //         holder.style.height = slideheight / 3 - 15 + "px";
      //       });
      //     }
      //   },
      //   breakpoints: {
      //     650: {
      //       slidesPerView: 1
      //     }
      //   }
      // },
      // activeScroll: 1,
      // totalScroll: 0,
      // transformPercent: 0,
      // slideHeight: 0,
      // currentHeight: 0,
      // swiper: null,
      isMobile: false
    };
  },
  mounted() {
    if (window.innerWidth < 650) {
      this.isMobile = true;
    }
    // this.totalScroll = document.querySelectorAll(".controller_hold").length;
    // if (document.querySelector(".swiper-container").clientHeight > 0) {
    //   this.slideHeight =
    //     document.querySelector(".swiper-container").clientHeight / 3;
    // }
    // this.swiper = this.$refs.trustedSlider.swiper;

    $(function(){
      $('.slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slider-nav',
        responsive: [
          {
            breakpoint: 992,
            settings: {
              dots: true,
            }
          }
        ]
      });
      $('.slider-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical: true,
        asNavFor: '.slider-for',
        dots: false,
        focusOnSelect: true,
        verticalSwiping: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              vertical: false,
            }
          },
          {
            breakpoint: 768,
            settings: {
              vertical: false,
            }
          },
          {
            breakpoint: 580,
            settings: {
              vertical: false,
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 380,
            settings: {
              vertical: false,
              slidesToShow: 2,
            }
          }
        ]
      });
    });
  },
  methods: {
    // handleNext() {
    //   if (this.activeScroll < this.totalScroll - 1) {
    //     this.activeScroll++;
    //     this.currentHeight = this.currentHeight - this.slideHeight;
    //     document.querySelector(
    //       ".controller_track"
    //     ).style.transform = `translate(0, ${this.currentHeight}px)`;
    //     this.$refs.trustedSlider.$swiper.slideTo(this.activeScroll + 1);
    //   }
    // },
    // handlePrev() {
    //   if (this.activeScroll >= 1) {
    //     this.activeScroll--;
    //     this.currentHeight = this.currentHeight + this.slideHeight;
    //     document.querySelector(
    //       ".controller_track"
    //     ).style.transform = `translate(0, ${this.currentHeight}px)`;
    //     // console.log(this.activeScroll);
    //     this.$refs.trustedSlider.$swiper.slideTo(this.activeScroll + 1);
    //   }
    // }
  },
};
