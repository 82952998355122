
export default {
  data: () => {
    return {
      modalIndex: 0,
      mySwiper:null,
      readFullInterview:null,
      dynamicModalClass: 'sc_modal Read_Interview_modal',
      expandedIndex: 0,
    }
  },
  mounted() {
    this.mySwiper = new Swiper(".swiper-container", {
      spaceBetween: 40,
      slidesPerView: 1.3,
      centeredSlides: true,
      initialSlide: 1,
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
      },
      navigation: {
        nextEl: ".swiper-button-next-desk",
        prevEl: ".swiper-button-prev-desk",
      },
      // roundLengths: true,
      loop: false,
      // loopAdditionalSlides: 30,

      breakpoints: {
        300: {
          slidesPerView: 1,
          spaceBetween: 20,
          centeredSlides: true,
          navigation: {
            nextEl: ".swiper-button-next-mob",
            prevEl: ".swiper-button-prev-mob",
          },
        },
        768: {
          slidesPerView: 1.3,
          spaceBetween: 40
        }
      }
    });
  },
  methods: {
    openModal(val) {
      this.$refs["readInterview"].show();
      this.readFullInterview = val;
    },
    onModalShown() {
      if (this.readFullInterview && this.readFullInterview.is_full_quote) {
        this.dynamicModalClass += ' customHeight';
      }
    },
    onModalHidden() {
      this.dynamicModalClass = 'sc_modal Read_Interview_modal';
    },
    toggleFaq(index) {
      if (this.expandedIndex === index) {
        this.expandedIndex = null;
      } else {
        this.expandedIndex = index;
      }
    },
  }
}
