
export default {
  data() {
    return {
      activeIndex: 0
    };
  },
  mounted(){},
  methods: {
    changeTab(index) {
      this.activeIndex = index;
      setTimeout(() => {
        this.closeDropdown();
      }, 200);
    },
    closeDropdown() {
      document.querySelector(".custom-dropdown").classList.remove("active");
    },
    openDropdown() {
      document.querySelector(".custom-dropdown").classList.add("active");
    }
  }
};
