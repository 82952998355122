
import Section5 from "@/components/global-components/section-components/section5";
export default {
  components: {
    section1: () => import("~/components/global-components/section-components/section1.vue"),
    Section5
  },
  scrollToTop: true,
  props: {
    parent: {
      type: String,
      require: true,
      default: ""
    }
  },
  data: () => {
    return {
      postType: null,
      banner: false,
      news_centers: false,
      breadscrum: {
        post_type: "page",
        ancestors: []
      },
      solutions: null,
      isPressRelease:false,
    };
  },
  async fetch() {
    try {
      const { store } = this.$nuxt.context;

      const res = await store.getters["news-center/newsCenterData"];
      let item_specific_data = {
        should_the_api_for_this_page_or_item_have_header_and_footer_menu: false,
        custom_css: "",
        contact_us_cta: {
          text: "Contact Us",
          url: "/contact-us",
          open_in: "New Window"
        },
        secondary_menu_list: [],
        show_secondary_menu_on_this_page: "yes",
        show_custom_links: "no"
      };
      store.commit("l1/updateItemSpecificData", item_specific_data);
      if (res.status === 200) {
        this.news_centers = res.data;
        let featured_image = this.news_centers.featured_image
          ? this.news_centers.featured_image.sizes.xxl
          : null;
        let post_name = this.news_centers.type_name;
        let title = this.news_centers.title;

        let post_breadscrumb = "";
        switch (post_name) {
          case "Press Release":
            post_breadscrumb = { slug: "press-release", name: "Press release" };
            break;
          case "Customer story":
            post_breadscrumb = {
              slug: "customer-story",
              name: "Customer story"
            };
            break;
          case "Media Alert":
            post_breadscrumb = {
              slug: "media-alert",
              name: "Media alert"
            };
            break;
          case "News Coverage":
            post_breadscrumb = {
              slug: "news-coverage",
              name: "News coverage"
            };
            break;
          default:
            post_breadscrumb = {};
        }
        this.breadscrum.ancestors.push(
          { slug: "", name: title },
          post_breadscrumb
        );

        this.$store.commit("l1/updateBreadcrumbs", this.breadscrum);

        if (this.parent === "prize-winner") {
          post_name = "";
          title = "";
          featured_image = this.news_centers.featured_image.url;
        }
        this.banner = {
          sub_title: post_name,
          title: title,
          background_image: {
            url: featured_image
          }
        };

        if (res.data.solutions) {
          res.data.solutions.reference_id = "related_solutions";
        }
        this.solutions = res.data.solutions;
      }
    } catch (error) {
      console.error(error);
    }
  },
  head() {
    return {
      htmlAttrs: {
        lang: "en"
      },
      title: this.$store.getters["meta/metaTitle"],
      meta: this.$store.getters["meta/meta"],
      link: [
        {
          rel: "canonical",
          href: this.$store.getters["meta/canonicalUrl"]
            ? this.$store.getters["meta/canonicalUrl"]
            : "https://www.tatacommunications.com" + this.$route.fullPath
        }
      ]
    };
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    this.$store.commit("l1/updatePageTitle", "");
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
};
