
  export default {
    props: {
      ctaContent: {
        type: String,
        default: ""
      },
      redirectLink: {
        type: String,
        default: ""
      },
      target: {
        type: String,
        default: ""
      },
      uniqueId: {
        type: String,
        default:""
      }
    },
    methods: {
      urlLink(args) {
        if (args.indexOf("http://") == 0 || args.indexOf("https://") == 0) {
          return true;
        } else if (args === "javascript:void(0)") {
          return false;
        } else if (args || args === undefined || args === null || args === "") {
          return false;
        } else {
          return true;
        }
      }
    }
  };
  