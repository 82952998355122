
export default {
  data: () => {
    return {
      showTickerBanner: true,
      swiper:null,
    };
  },
  
  methods: {
    toggleTickerBannerVisibility() {
      this.showTickerBanner = !this.showTickerBanner; // Toggle the visibility of the ticker-banner section
    },
  },
  mounted(){
    this.swiper = new Swiper(".myMultipleTickerSwiper", {
        direction: "vertical",
        loop:true,
        autoplay: {
            delay: 2000,
            pauseOnMouseEnter: true,
        },
        
    });
  }
};
