
import { Glide, GlideSlide } from "vue-glide-js";
import { mapGetters } from "vuex";
import tataHeader from "~/components/tataHeaderNew.vue";
import tataFooter from "~/components/tataFooter";

export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
    tataHeader: tataHeader,
    tataFooter: tataFooter
  },
  data: () => {
    return {
      resourceData: null,
      resourceRightData: null,
      resource: false,
      data: [],
      uniqueKey: 0
    };
  },
  head: {
    title: "Tata Communications"
  },
  computed: {
    ...mapGetters("resource", ["featuredResource"]),
    scrollToTopSection : () =>import("~/components/global-components/tata-scroll-to-top-cta")
  },
  watch: {
    $route: function (to, from) {
      if (to.path !== from.path) this.uniqueKey++; // Adding unique key to nuxt on route change for transitions
    }
  }
};
