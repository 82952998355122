
export default {
    data: () => {
        return {
            customerTestimonialSwiper:null,
            fullQuote: null,
            paginationList: 3,
            showAllTestimonials: false,
            videoUrl:null,
            redirectLink:null,
            // allItems: []
        };
    },
    
    computed: {
        visibleTestimonials() {
            // Slice the original array based on the current pagination count or show all
            return this.showAllTestimonials
                ? this.$attrs.all_testimonial
                : this.$attrs.all_testimonial.slice(0, this.paginationList);
        },

        showLoadButton() {
            // Show the "Load More" button if there are more testimonials to load or "Load Less" if all are visible
            return (
                this.paginationList < this.$attrs.all_testimonial.length ||
                this.showAllTestimonials
            );
        },
    },
    methods: {
        urlLink(args) {
            if (args.indexOf("http://") == 0 || args.indexOf("https://") == 0) {
                return true;
            } else if (args || args === undefined || args === null || args === "") {
                return false;
            } else {
                return true;
            }
        },
        showVideoModal(val) {
            this.$refs["videoModal"].show();
            // this.bannerUrl = val;
            const videoSrc = val;
            var videoSrcNew = videoSrc + "/?autoplay=1&rel=0&mute=1";
            this.videoUrl = videoSrcNew;
        },
        openFullQuotes(val) {
            this.$refs["quote"].show();
            this.fullQuote = val;
        },
        loadMore() {
            // Increase the pagination count to show the next set of testimonials
            this.paginationList += 3;
        },

        toggleLoadMore() {
            // Toggle between "Load More" and "Load Less" functionality
            this.showAllTestimonials = !this.showAllTestimonials;
        },
    },
    mounted(){
        this.customerTestimonialSwiper = new Swiper(".customerTestimonialSwiper", {
            spaceBetween: 30,
            slidesPerView: 'auto',
            centeredSlides: false,
            // slidesPerGroupSkip: 1,
            grabCursor: true,
            keyboard: {
                enabled: true,
            },
            scrollbar: {
                el: ".swiper-scrollbar",
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });
    },
}
